<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="PREVENTIVE_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="preventiveTypeCd"
            label="유지보수 구분"
            v-model="searchParam.preventiveTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-air-preventive
            type="search"
            label="방지시설"
            name="envAirMstPreventiveId"
            v-model="searchParam.envAirMstPreventiveId">
          </c-air-preventive>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="방지시설별 유지보수 이력"
      tableId="grid"
      :columnSetting="false"
      :isFullScreen="false"
      :columns="grid.columns"
      :merge="grid.merge"
      :filtering="false"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="detailPopup" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'air-outlet-part',
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'envAirDailyResultPreventiveHistoryId' },
          { index: 1, colName: 'envAirDailyResultPreventiveHistoryId' },
          { index: 2, colName: 'envAirDailyResultPreventiveHistoryId' },
          { index: 3, colName: 'envAirDailyResultPreventiveHistoryId' },
          { index: 4, colName: 'envAirDailyResultPreventiveHistoryId' },
          { index: 5, colName: 'envAirDailyResultPreventiveHistoryId' },
          { index: 8, colName: 'envAirDailyResultPreventiveHistoryId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'startDt',
            field: 'startDt',
            label: '유지보수 일자',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'preventiveTypeName',
            field: 'preventiveTypeName',
            label: '유지보수 구분',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'envAirMstPreventiveName',
            field: 'envAirMstPreventiveName',
            label: '방지시설',
            align: 'center',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '작업관리자',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'contents',
            field: 'contents',
            label: '작업 내용',
            align: 'left',
            sortable: true,
          },
          {
            name: 'partName',
            field: 'partName',
            label: '교체 소모품',
            align: 'center',
            type: 'link',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'partCnt',
            field: 'partCnt',
            label: '교체 소모품수량',
            align: 'right',
            type: 'cost',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'historyFile',
            field: 'historyFile',
            label: '유지보수 사진',
            align: 'center',
            sortable: true,
            type: 'file',
            style: 'width: 250px',
          },
        ],
        data: [],
      },
      nowplantCd: '',
      detailUrl: '',
      listUrl: '',
      insertUrl: '',
      deleteUrl: '',
      editable: true,
      searchParam: {
        plantCd: null,
        preventiveTypeCd: null,
        envAirMstPreventiveId: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.air.daily.preventive.history.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col) {
      this.detailPopup(row, col);
    },
    detailPopup(result, col) {
      if (col && col.name == 'partName') {
        this.popupOptions.target = () => import(`${"./airPartDetail.vue"}`);
        this.popupOptions.title = '방지시설 소모품 상세';
        this.popupOptions.param = {
          partCd: result ? result.partCd : '',
        };
      } else {        
        this.popupOptions.target = () => import(`${"./airOutletPartDetail.vue"}`);
        this.popupOptions.title = '방지시설 유지보수 상세';
        this.popupOptions.param = {
          envAirDailyResultPreventiveHistoryId: result ? result.envAirDailyResultPreventiveHistoryId : '',
        };
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>